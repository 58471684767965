<template>
  <div>

    <b-button type="is-info" icon-left="plus" tag="router-link" :to="{ path: '/quests/create' }">CREATE NEW QUEST</b-button>

    <h4 class="title has-text-centered is-4 mt-6">Search</h4>

    <form @submit="handleSubmit">
      <b-field grouped>
        <b-field label="Quest ID" custom-class="is-small">
          <b-input v-model="formData.id" size="is-small"></b-input>
        </b-field>

        <b-field label="Title" custom-class="is-small">
          <b-input v-model="formData.title" size="is-small"></b-input>
        </b-field>

        <b-field label="Story" custom-class="is-small">
          <b-select v-model="formData.quest_story_id" size="is-small">
            <option></option>
            <option v-for="story in stories" :key="story.id" :value="story.id">{{ story.title }}</option>
          </b-select>
        </b-field>

        <b-field label="Giant Favor" custom-class="is-small">
          <b-select v-model="formData.reward_giant" size="is-small">
            <option></option>
            <option v-for="giant in giants" :key="giant.id" :value="giant.id">{{ giant.name }}</option>
          </b-select>
        </b-field>

        <b-field label="Repeatable" custom-class="is-small">
          <b-select v-model="formData.repeatable" size="is-small">
            <option></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </b-select>
        </b-field>

        <b-field label="Published" custom-class="is-small">
          <b-select v-model="formData.published" size="is-small">
            <option></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </b-select>
        </b-field>
      </b-field>

      <b-button type="is-info" icon-left="search" native-type="submit" size="is-small">Search</b-button>
    </form>

  </div>
</template>

<script>
import { Quest } from '@/services/api';
import { giants } from '../../config/quests';

export default {
  name: 'quest-search-form',
  data() {
    return {
      errors: [],
      formData: {
        ...this.$route.query,
      },
      loadingForm: false,
      sent: false,
      stories: [],
      giants,
    };
  },
  mounted() {
    Quest.getStories()
      .then((data) => {
        this.stories = data;
      });
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      const params = {
        ...this.formData,
        page: 1,
      };

      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/quests?${queryString}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
