<template>
  <section class="section">
    <quest-search-form></quest-search-form>

    <h4 class="title has-text-centered is-4">Quests</h4>
    <b-table
      :data="quests.data"
      :striped="true"
      :hoverable="true"
      :loading="loadingQuests"
      class="mt-4 clickable-table user-table"
      @click="goToQuest"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column field="title" label="Title" v-slot="props">
        <div>
          <div><strong>{{ props.row.title }}</strong></div>
          <div v-if="props.row.quest_story_id" style="font-style: italic">
            Story: {{ props.row.quest_story_id }} #{{ props.row.story_order }}
          </div>
        </div>
      </b-table-column>

      <b-table-column field="reward_giant" label="Giant Favor" v-slot="props">
        <p>{{ props.row.reward_giant }}</p>
      </b-table-column>

      <b-table-column field="repeatable" label="Repeatable" v-slot="props">
        <p>
          <b-icon pack="fa" icon="redo" :type="props.row.repeatable ? 'is-info' : 'is-light'" size="is-medium"></b-icon>
        </p>
      </b-table-column>

      <b-table-column field="published" label="Published" v-slot="props">
        <p>
          <b-icon v-if="props.row.published" pack="fa" icon="check" type="is-success" size="is-medium"></b-icon>
          <b-icon v-else pack="fa" icon="times" type="is-danger" size="is-medium"></b-icon>
        </p>
      </b-table-column>
    </b-table>

    <b-pagination
      class="mt-4"
      v-model="quests.current_page"
      :total="quests.total"
      :per-page="quests.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import QuestSearchForm from '@/components/Quests/QuestSearchForm.vue';

export default {
  name: 'Quests',
  components: { QuestSearchForm },
  computed: {
    ...mapState('quest', ['loadingQuests', 'quests']),
  },
  mounted() {
    this.loadQuests();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.loadQuests();
    },
  },
  methods: {
    loadQuests() {
      this.$store.dispatch('quest/loadQuests', this.$route.query);
    },
    goToQuest(row) {
      this.$router.push({ path: `/quests/${row.id}` });
    },
    handlePageChange(pageNum) {
      const params = {
        ...this.$route.query,
        page: pageNum,
      };
      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/quests?${queryString}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
